"use client";

import trophyImage from "@/images/eriick-trophy.jpg";
import capImage from "@/images/planhart-graduation-cap.png";
import quoteImage from "@/images/planhart-quote.png";
import scientistImage from "@/images/planhart-scientist.png";
import starImage from "@/images/planhart-star.png";
import tubeImage from "@/images/planhart-tube.png";
import videoImage from "@/images/planhart-video.jpg";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

const Divider = () => (
  <div className="my-6 h-[1px] bg-transparent bg-planhart-divider bg-[length:8px] lg:my-12"></div>
);
const addMonths = (date, months) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
};

const date = addMonths(new Date(), 2);

const Thumbnail = (
  <Image
    src={videoImage}
    alt="Sir Eriick Planhart, PhD"
    className="cursor-pointer rounded-2xl"
    priority={true}
    sizes="100vw, (min-width: 1152px) 1152px"
    style={{
      display: "block",
      aspectRatio: "1160 / 600",
    }}
  />
);

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export default function Planhart() {
  return (
    <>
      <div className="m-auto w-screen max-w-[74rem] px-4 py-4 lg:py-20">
        <div className="overflow-hidden rounded-2xl shadow-[0px_80px_160px_0px_rgba(11,_179,_235,_0.40)]">
          <div style={{ aspectRatio: "1160 / 600" }} className="relative">
            <ReactPlayer
              url="https://theory11.wistia.com/medias/kdsjjghebq"
              controls={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              width="100%"
              height="auto"
              playIcon={<></>}
              light={Thumbnail}
              config={{
                options: {
                  fitStrategy: "cover",
                  autoplay: true,
                  fullscreenOnRotateToLandscape: false,
                },
              }}
            />
          </div>
        </div>
        <h2 className="mb-4 mt-12 text-center uppercase text-[#53D3FF]">
          welcome to the official website of
        </h2>
        <h1 className="text-center text-4xl md:text-6xl lg:text-8xl">
          Sir Eriick Planhart
        </h1>
        <Divider />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-[1fr,55%] md:gap-10 lg:gap-20">
          <Image
            src={scientistImage}
            alt="Sir Eriick Planhart, PhD"
            className="relative max-w-full justify-self-center drop-shadow-[0px_80px_160px_rgba(11,_179,_235,_0.40)] md:left-[-8%] md:justify-self-start"
            sizes="100vh, (min-width: 768px) 40vw, (min-width: 1200px) 436px"
          />
          <div className="flex flex-col gap-8">
            <blockquote className="relative text-2xl leading-normal md:text-3xl md:leading-normal">
              <Image
                src={quoteImage}
                alt="quotation mark"
                className="absolute left-[-62px]"
                width={30}
                height={39}
                priority={true}
              />
              The human mind is a mosaic of{" "}
              <Link href="/images/Planhart-Puzzle_REDACTED.jpg" target="_blank">
                countless puzzle pieces
              </Link>{" "}
              &mdash; each memory, thought, and feeling a fragment of something
              larger. It’s only in piecing together those moments that we begin
              to glimpse the full image of who we are.
            </blockquote>
            <cite className="text-lg not-italic text-[#BCC4D9]">
              – Sir Eriick Planhart
            </cite>
          </div>
        </div>
        <Divider />
        <h1 className="mb-6 flex gap-4 text-2xl lg:mb-12 lg:mb-6">
          <Image
            src={tubeImage}
            alt="test tube"
            className="drop-shadow-[0px_0px_24px_#01BDFE]"
            width={20}
            height={40}
          />
          Upcoming Speaking Engagements
        </h1>
        <div className="lg:gap-y-2rounded-lg my-4 grid grid-cols-1 gap-x-20 gap-y-4 bg-[#b7b2b21f] p-8 lg:grid-cols-[96px,1fr,125px]">
          <h2 className="text-xl text-[#53D3FF]">
            {date.getMonth() + 1}.12.{date.getFullYear()}
          </h2>
          <h2 className="text-xl">
            Unlocking the Quantum{" "}
            <Link href="/images/Planhart-Puzzle_REDACTED.jpg" target="_blank">
              Puzzle
            </Link>{" "}
            : Science and Intelligence
          </h2>
          <p className="text-[#BCC4D9] lg:col-start-2 lg:row-start-2">
            Sir Eriick Planhart explores how the principles of quantum mechanics
            and advanced mathematics can enhance human cognitive abilities,
            drawing connections between scientific discovery and the limits of
            human intelligence.
          </p>

          <button className="pointer-events-none max-w-[125px] self-start rounded bg-[#444369] px-6 py-3 lg:col-start-3 lg:row-start-2 lg:justify-self-end">
            Sold out
          </button>
        </div>
        <div className="lg:gap-y-2rounded-lg my-4 grid gap-x-20 gap-y-4 bg-[#b7b2b21f] p-8 lg:grid-cols-[96px,1fr,125px]">
          <h2 className="text-xl text-[#53D3FF]">
            {date.getMonth() + 1}.19.{date.getFullYear()}
          </h2>
          <h2 className="text-xl">
            Chaos Theory and the Complexity of the Mind
          </h2>
          <p className="text-[#BCC4D9] lg:col-start-2 lg:row-start-2">
            This lecture delves into the fascinating parallels between chaos
            theory in mathematics and the intricate workings of the human brain,
            offering insights into how seemingly random processes lead to order
            and innovation.
          </p>

          <button className="pointer-events-none max-w-[125px] self-start rounded bg-[#444369] px-6 py-3 lg:col-start-3 lg:row-start-2 lg:justify-self-end">
            Sold out
          </button>
        </div>
        <div className="lg:gap-y-2rounded-lg my-4 grid gap-x-20 gap-y-4 bg-[#b7b2b21f] p-8 lg:grid-cols-[96px,1fr,125px]">
          <h2 className="text-xl text-[#53D3FF]">
            {date.getMonth() + 1}.26.{date.getFullYear()}
          </h2>
          <h2 className="text-xl">
            Symmetry in Science, Innovation in Thought: The Future of AI
          </h2>
          <p className="text-[#BCC4D9] lg:col-start-2 lg:row-start-2">
            In this talk, Sir Planhart explores the groundbreaking role of
            symmetry theory in quantum computing and AI, showing how the next
            wave of technology stems from the fusion of physics and human
            ingenuity.
          </p>

          <button className="pointer-events-none row-start-2 max-w-[125px] self-start rounded bg-[#444369] px-6 py-3 lg:col-start-3 lg:justify-self-end">
            Sold out
          </button>
        </div>
        <Divider />
        <h1 className="mb-6 flex gap-4 text-2xl lg:mb-12 lg:mb-6">
          <Image
            src={capImage}
            alt="graduation cap"
            className="drop-shadow-[0px_0px_24px_#01BDFE]"
            width={52}
            height={38}
          />
          Biography
        </h1>
        <p className="mb-6 text-lg md:mb-12">
          Noted for his exceptional intellect, Sir Eriick Planhart was born in
          Vienna in 1949 and became a pioneering figure in theoretical physics
          and applied mathematics. Educated at the University of Vienna, his
          early work in quantum mechanics and non-Euclidean geometry captivated
          the scientific world.
        </p>
        <p className="mb-6 text-lg md:mb-12">
          International recognition came swiftly as his research into subatomic
          particles led to the creation of the &quot;Planhart Symmetry
          Theory,&ldquo; transforming the understanding of particle wave
          duality. Lauded for this breakthrough, he earned the prestigious
          Goldstein-Marks Medal for Quantum Discoveries in 1971.
        </p>
        <p className="mb-6 text-lg md:mb-12">
          Planhart’s creative genius extended beyond research when, in 1973, he
          founded the Planhart Cortex Conclave, a global{" "}
          <Link href="/images/Planhart-Puzzle_REDACTED.jpg" target="_blank">
            puzzle
          </Link>{" "}
          competition that tested human intelligence. As an innovative blend of
          mathematics, physics, and cognitive science, the Conclave soon became
          an annual event revered by scholars worldwide.
        </p>
        <p className="mb-6 text-lg md:mb-12">
          The intricate{" "}
          <Link href="/images/Planhart-Puzzle_REDACTED.jpg" target="_blank">
            puzzles
          </Link>{" "}
          intertwined with scientific principles attracted participants from
          leading research institutions. Royal recognition came in 1981 when he
          was awarded the Royal Medal of Cognitive Innovation by the Society for
          Advanced Scientific Thought. Internationally, the Conclave is still
          regarded as one of the toughest intellectual challenges. Continuing
          his research, Sir Planhart explored fields like chaos theory, quantum
          field theory, and fractal dimensions. Knighted in 1989 for his
          contributions to science, he laid the groundwork for quantum computing
          and artificial intelligence applications.
        </p>
        <p className="mb-6 text-lg md:mb-12">
          His legacy was further cemented with the Planhart Prize for
          Mathematical Ingenuity, awarded to him in 2002. Advances he made in
          both theoretical and applied sciences continue to inspire new
          generations of scientists. Renowned as both a scholar and{" "}
          <Link href="/images/Planhart-Puzzle_REDACTED.jpg" target="_blank">
            puzzle
          </Link>{" "}
          master, Sir Planhart&lsquo;s intellect crossed multiple disciplines.
          Respected for his ability to merge complex theory with innovative
          thinking, he left an indelible mark on modern science. Indeed, his
          contributions have not only changed our understanding of the universe
          but have also elevated the global standards of intellectual
          achievement. Sir Planhart remains a towering figure in scientific
          history, revered by peers and successors alike.
        </p>
        <Divider />
        <h1 className="mb-6 flex gap-4 text-2xl lg:mb-12 lg:mb-6">
          <Image
            src={tubeImage}
            alt="test tube"
            className="drop-shadow-[0px_0px_24px_#01BDFE]"
            width={20}
            height={40}
          />
          Accomplishments
        </h1>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <div className="relative overflow-hidden rounded-2xl bg-[#b7b2b21f]">
            <Image
              src={trophyImage}
              sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
              alt="Sir Eriick Planhart with Trophy"
              className="absolute left-0 top-0 block h-full w-full object-cover"
            />
          </div>
          <div className="rounded-2xl bg-[#b7b2b21f] px-6 py-8">
            <Image
              src={starImage}
              alt="star"
              className="mb-6"
              width={35}
              height={35}
            />
            <h2 className="mb-4 text-xl">Recipient of Prestigious Awards</h2>
            <p className="text-[#BCC4D9]">
              Awarded the Goldstein-Marks Medal for Quantum Discoveries (1971)
              and the Royal Medal of Cognitive Innovation (1981).
            </p>
          </div>
          <div className="rounded-2xl bg-[#b7b2b21f] px-6 py-8">
            <Image
              src={starImage}
              alt="star"
              className="mb-6"
              width={35}
              height={35}
            />
            <h2 className="mb-4 text-xl">
              Founder of the Planhart Cortex Conclave
            </h2>
            <p className="text-[#BCC4D9]">
              Established an exclusive global{" "}
              <Link href="/images/Planhart-Puzzle_REDACTED.jpg" target="_blank">
                puzzle
              </Link>{" "}
              competition in 1973, testing the limits of human intelligence and
              problem-solving skills.
            </p>
          </div>
          <div className="rounded-2xl bg-[#b7b2b21f] px-6 py-8">
            <Image
              src={starImage}
              alt="star"
              className="mb-6"
              width={35}
              height={35}
            />
            <h2 className="mb-4 text-xl">Developed Planhart Symmetry Theory</h2>
            <p className="text-[#BCC4D9]">
              Revolutionized the understanding of particle wave duality in
              quantum mechanics, earning international recognition.
            </p>
          </div>
          <div className="rounded-2xl bg-[#b7b2b21f] px-6 py-8">
            <Image
              src={starImage}
              alt="star"
              className="mb-6"
              width={35}
              height={35}
            />
            <h2 className="mb-4 text-xl">
              Knighted for Scientific Contributions
            </h2>
            <p className="text-[#BCC4D9]">
              Knighted in 1989 for his pioneering work in physics and
              mathematics, and honored with the Planhart Prize for Mathematical
              Ingenuity in 2002.
            </p>
          </div>
          <div className="rounded-2xl bg-[#b7b2b21f] px-6 py-8">
            <Image
              src={starImage}
              alt="star"
              className="mb-6"
              width={35}
              height={35}
            />
            <h2 className="mb-4 text-xl">Expert in Advanced Sciences</h2>
            <p className="text-[#BCC4D9]">
              Specialized in chaos theory, quantum field theory, and fractal
              dimensions, contributing to the foundation of quantum computing
              and AI advancements.
            </p>
          </div>
        </div>
        <Divider />
        <footer className="text-center text-lg text-white2 opacity-65">
          © {new Date().getFullYear()} Sir Eriick Planhart, PhD | All Rights
          Reserved.
        </footer>
      </div>
    </>
  );
}
